import { Breadcrumb, Col, Divider, Row, Typography, Button, Card } from "antd";
import NavBar from "../components/navbar";

export default function Vrd() {
  return (
    <>
      <NavBar />
      <div
        style={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1592581912637-222dcc696ac4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80)",
          height: 200,
          width: "100%",
          backgroundPosition: "top",
          backgroundSize: "cover"
        }}
      >
        <div
          style={{
            backgroundImage: "linear-gradient( #000000, #00000095)",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0px 7%"
          }}
        >
          <Typography style={{ color: "white", fontSize: 30 }}>
            V.R.D
          </Typography>
        </div>
      </div>
      <div style={{ padding: "40px 7%" }}>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Card style={{ padding: 0 }}>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="vrd"
              >
                V.R.D
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="maconnerie-generale"
              >
                Maçonnerie Generale
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="second-oeuvre"
              >
                Second Oeuvre
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="piscine"
              >
                Piscine
              </Button>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            <div
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1636322884978-ddd80070debe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)",
                width: "100%",
                height: "240px",
                borderRadius: 6
              }}
            />
            <Typography style={{ fontSize: 20, marginTop: "40px" }}>
              C’est l’étape primordiale qui va permettre de préparer le terrain
              sur lequel est projetée la construction d’un bâtiment
              d’habitation, industriel ou commercial. Ces opérations peuvent
              être les suivantes :
              <Divider style={{ marginBottom: 0 }} />
              <Typography>
                <span style={{ color: "gray", fontSize: 18 }}>
                  <br />
                  - Travaux de fouilles <br />
                  - Tranchées <br />
                  - Enlèvement de la terre <br />
                  - Transport des déchets <br />
                  - Réalisation de murs de soutènement <br />
                  - Autres actions antérieures à une construction, un
                  aménagement ou une transformation de l’espace extérieur <br />{" "}
                  <br />
                  Travaux de voirie :<br /> - Réalisation de voies d’accès
                  <br />
                  - Aménagement des espaces extérieurs en pavés naturels ou
                  reconstitués, émulsion gravillonnée, ou encore béton
                  désactivé, enrobé noir ou rouge <br />- Mise en œuvre des
                  réseaux d’alimentation en eau, en électricité et en
                  télécommunication <br />
                  <br />
                  Assainissement : <br />- Mise en œuvre des réseaux
                  d’évacuation d’eau de pluie ou d’eaux usées
                </span>
              </Typography>
            </Typography>
          </Col>
        </Row>
      </div>
    </>
  );
}
