import NavBar from "../components/navbar";
import { Typography, Col, Row, Divider, Card, Button } from "antd";

export default function MaconnerieGenerale() {
  return (
    <>
      <NavBar />
      <div
        style={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1592581912637-222dcc696ac4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80)",
          height: 200,
          width: "100%",
          backgroundPosition: "top",
          backgroundSize: "cover"
        }}
      >
        <div
          style={{
            backgroundImage: "linear-gradient( #000000, #00000095)",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0px 7%"
          }}
        >
          <Typography style={{ color: "white", fontSize: 30 }}>
            Maçonnerie Générale
          </Typography>
        </div>
      </div>
      <div style={{ padding: "40px 7%" }}>
        <Row gutter={[40, 10]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Card style={{ padding: 0 }}>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="vrd"
              >
                V.R.D
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="maconnerie-generale"
              >
                Maçonnerie Generale
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="second-oeuvre"
              >
                Second Oeuvre
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="piscine"
              >
                Piscine
              </Button>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            <div
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1574757987642-5755f0839101?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80)",
                width: "100%",
                height: "240px",
                borderRadius: 6,
                backgroundPosition: "center"
              }}
            />
            <Typography
              style={{ fontSize: 20, marginTop: "40px", textAlign: "justify" }}
            >
              Indispensables à la construction et à la rénovation de tout
              ouvrage, les travaux de maçonnerie nécessitent un savoir-faire
              technique maîtrisé et recouvrent un large panel de techniques. Nos
              collaborateurs sont également qualifiés pour la réalisation du
              gros œuvre (fondations, soubassement, etc.) et l’élévation des
              murs (murs extérieurs) tous matériaux dont briques, parpaings,
              briques monomurs, que pour le second œuvre comme le coulage de
              chape ou de dalle. Nous pouvons également intervenir dans la
              démolition d’un bâtiment ou opérer une rénovation intégrale avec
              aménagement et restructuration du lieu.
              <Divider />
              <Typography style={{ textAlign: "justify" }}>
                <span style={{ color: "gray", fontSize: 18 }}>
                  Couverture / Charpente <br />
                  La couverture comprend plusieurs spécialités, telles que
                  l’entretien, le nettoyage, le remplacement, la modification ou
                  la surélévation. Notre équipe de couvreurs zingueurs
                  professionnels est hautement qualifiée pour entretenir,
                  réparer, remplacer et traiter votre toiture Nous intervenons
                  sur tout type de couverture, tuile, ardoise et zinc : <br />
                  - Nettoyage et traitement de toiture, démoussage <br />
                  - Réfection et remplacement des zingueries <br />
                  - Détection des fuites et infiltrations faîtage <br />
                  - Réparation et réfection sur tout type de toiture <br />
                  - Traitement de charpente <br /> - Ouverture de toit, velux{" "}
                  <br /> <br />
                  Rénovation de façade <br />
                  Nous nettoyons et entretenons les murs extérieurs : <br />
                  - Traitement antimousse <br />
                  - Traitement antipollution <br />
                  - Décapage, peeling Sablage <br />
                  - Hydrogommage <br />
                  - Nettoyage, ponçage
                  <br />
                  Imperméabilisation
                  <br />
                  <br />
                  Ravalement des façades :<br /> - Maisons
                  <br /> - Immeubles <br />
                  - Murs Béton, brique, pierre <br />
                  - Rebouchage de fissures <br />- Ravalement décoratif
                  <br /> <br />
                  Finition de façades : <br />
                  - Crépi <br />- Enduit gratté <br />
                  - Enduit isolant
                  <br />- Peinture
                </span>
              </Typography>
            </Typography>
          </Col>
        </Row>
      </div>
    </>
  );
}
