import { Typography } from "antd";
import picture1 from "../assets/picture1.jpeg";
import picture2 from "../assets/picture2.jpeg";
import picture3 from "../assets/picture3.jpeg";
import picture4 from "../assets/picture4.jpeg";
import picture5 from "../assets/picture6.jpeg";

const data = [
  {
    src: picture1
  },
  {
    src: picture2
  },
  {
    src: picture3
  },
  {
    src: picture4
  },
  {
    src: picture5
  }
];

const imageDisp = data.map((data) => (
  <div
    style={{
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      borderRadius: 10
    }}
  >
    <div
      style={{
        backgroundImage: "url(" + data.src + ")",
        height: 500,
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 10,
        backgroundColor: "transparent",
        padding: "10%"
      }}
    ></div>
  </div>
));

export default imageDisp;
