import { Breadcrumb, Col, Divider, Row, Typography, Button, Card } from "antd";
import NavBar from "../components/navbar";

export default function Pool() {
  return (
    <>
      <NavBar />
      <div
        style={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1592581912637-222dcc696ac4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80)",
          height: 200,
          width: "100%",
          backgroundPosition: "top",
          backgroundSize: "cover"
        }}
      >
        <div
          style={{
            backgroundImage: "linear-gradient( #000000, #00000095)",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0px 7%"
          }}
        >
          <Typography style={{ color: "white", fontSize: 30 }}>
            Piscine
          </Typography>
        </div>
      </div>
      <div style={{ padding: "40px 7%" }}>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Card style={{ padding: 0 }}>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="vrd"
              >
                V.R.D
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="maconnerie-generale"
              >
                Maçonnerie Generale
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="second-oeuvre"
              >
                Second Oeuvre
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="piscine"
              >
                Piscine
              </Button>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            <div
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)",
                width: "100%",
                height: "240px",
                borderRadius: 6,
                backgroundPosition: "center",
                backgroundSize: "cover"
              }}
            />
            <Typography style={{ fontSize: 20, marginTop: "40px" }}>
              Distributeur des piscines MAGILINE depuis fin 2018. Nous disposons
              d’une équipe de techniciens spécialisée pour la réalisation de vos
              projets piscine MAGILINE, la rénovation de votre piscine (toutes
              marques) et l’entretien de votre piscine. PISCINE MAGILINE =
              PISCINE SANS CONTRAINTE iMAGI-X – piscine intelligente et
              connectée Un projet unique et sur-mesure De toutes les formes :
              Piscine rectangle Piscine carrée Piscine formes arrondies Piscine
              petite dimension XS Piscine Grande dimension XXL Piscine en angle
              Piscine sur mesure Piscine couloir de nage Piscine à débordement
              Piscine intérieure Liners ou membranes armées pour étanchéifier
              votre bassin Mousse confort : une protection et un confort unique
              Fonds pointe diamant, plat, à deux niveaux Nages à contre-courant
              Balnéo
            </Typography>
          </Col>
        </Row>
      </div>
    </>
  );
}
