import {
  Button,
  Col,
  Divider,
  Drawer,
  Popover,
  Row,
  Space,
  Typography
} from "antd";
import { useState } from "react";
import logo from "../assets/LogoTransparent.png";
import {
  CloseOutlined,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MailOutlined,
  MenuOutlined,
  PhoneOutlined
} from "@ant-design/icons";

export default function NavBar(props) {
  const [width, setWidth] = useState(document.body.offsetWidth);
  const [showMenu, setShowMenu] = useState(false);

  window.addEventListener("resize", () => setWidth(document.body.offsetWidth));

  if (width < 930) {
    return (
      <>
        <div
          style={{
            padding: "20px 4%",
            backgroundColor: "black",
            transition: "all 700ms"
          }}
        >
          <Row>
            <Col span={24 / 2}>
              <img src={logo} height={60} alt="Logo Constructions Simon" />
            </Col>
            <Col
              span={24 / 2}
              style={{
                display: "flex",
                alignItems: "center",
                direction: "rtl"
              }}
            >
              <Button
                onClick={() =>
                  showMenu ? setShowMenu(false) : setShowMenu(true)
                }
                icon={showMenu ? <CloseOutlined /> : <MenuOutlined />}
                style={{
                  backgroundColor: "#f6921c30",
                  color: "#f6921c",
                  border: "1px solid #f6921c",
                  borderRadius: 4,
                  fontFamily: "Space Grotesk"
                }}
              />
            </Col>
          </Row>
          {props.content}
        </div>
        <Drawer
          style={{ marginTop: 100 }}
          width="100vw"
          visible={showMenu}
          onClose={() => setShowMenu(false)}
        >
          <Typography style={{ fontSize: 20, fontFamily: "Space Grotesk" }}>
            Menu
          </Typography>
          <Divider />
          <Button
            block
            style={{ marginBottom: 18, textAlign: "left" }}
            type="text"
            href="/"
          >
            Accueil
          </Button>
          <Button
            block
            style={{ marginBottom: 18, textAlign: "left" }}
            type="text"
            href="vrd"
          >
            V.R.D
          </Button>
          <Button
            block
            style={{ marginBottom: 18, textAlign: "left" }}
            type="text"
            href="maconnerie-generale"
          >
            Maçonnerie Génerale
          </Button>
          <Button
            block
            style={{ marginBottom: 18, textAlign: "left" }}
            type="text"
            href="second-oeuvre"
          >
            Second Oeuvre
          </Button>
          <Button
            block
            style={{ marginBottom: 18, textAlign: "left" }}
            type="text"
            href="piscine"
          >
            Piscine
          </Button>

          <Button
            block
            style={{ marginBottom: 18, textAlign: "left" }}
            type="text"
            href="contact"
          >
            Contact
          </Button>
        </Drawer>
      </>
    );
  } else {
    return (
      <>
        <div
          className="banner"
          style={{
            backgroundColor: "#f6921c",
            padding: "10px 7%"
          }}
        >
          <Row>
            <Col span={12}>
              <Space size={10}>
                <Button
                  href="mailto:contact@constructions-simon.fr"
                  type="text"
                  icon={<MailOutlined />}
                  style={{
                    padding: 0,
                    color: "white",
                    background: "none",
                    fontFamily: "Space Grotesk"
                  }}
                >
                  contact@constructions-simon.fr
                </Button>
                <Divider type="vertical" style={{ borderColor: "white" }} />
                <Button
                  href="tel:+33358450082"
                  type="text"
                  icon={<PhoneOutlined />}
                  style={{
                    padding: 0,
                    color: "white",
                    background: "none",
                    fontFamily: "Space Grotesk"
                  }}
                >
                  03 58 45 00 82
                </Button>
              </Space>
            </Col>
            <Col span={12} style={{ textAlign: "end" }}>
              <Space>
                <Button
                  type="text"
                  href="https://www.instagram.com/constructions_simon/"
                  target="_blank"
                  icon={<InstagramOutlined style={{ fontSize: "20px" }} />}
                  style={{ color: "white" }}
                />
                <Button
                  type="text"
                  target="_blank"
                  href="https://fr-fr.facebook.com/ConstructionsSimon/"
                  icon={<FacebookOutlined style={{ fontSize: "20px" }} />}
                  style={{ color: "white" }}
                />
                <Button
                  href="https://www.linkedin.com/company/constructions-simon/"
                  target="_blank"
                  type="text"
                  icon={<LinkedinOutlined style={{ fontSize: "20px" }} />}
                  style={{ color: "white" }}
                />
              </Space>
            </Col>
          </Row>
        </div>
        <div style={{ padding: "20px 7%", backgroundColor: "black" }}>
          <Row>
            <Col span={6}>
              <img src={logo} height={80} alt="Logo Constructions Simon" />
            </Col>
            <Col span={12} style={{ textAlign: "center", display: "flex" }}>
              <Space style={{ margin: "auto" }}>
                <Button
                  href="/"
                  type="text"
                  style={{
                    color: "white",
                    fontSize: 15,
                    fontFamily: "Space Grotesk"
                  }}
                >
                  Accueil
                </Button>
                <Popover
                  color="volcano "
                  content={
                    <div style={{ width: "fit-content", padding: "10px 0px" }}>
                      <Space size={10} direction="vertical">
                        <Button
                          type="text"
                          style={{ textAlign: "left" }}
                          block
                          href="vrd"
                          target="_self"
                        >
                          V.R.D
                        </Button>
                        <Button
                          type="text"
                          style={{ textAlign: "left" }}
                          block
                          href="maconnerie-generale"
                        >
                          Maçonnerie Génerale
                        </Button>
                        <Button
                          type="text"
                          style={{ textAlign: "left" }}
                          block
                          href="second-oeuvre"
                        >
                          Second oeuvre
                        </Button>
                        <Button
                          type="text"
                          style={{ textAlign: "left" }}
                          block
                          href="piscine"
                        >
                          Piscine
                        </Button>
                      </Space>
                    </div>
                  }
                  placement="bottom"
                >
                  <Button
                    type="text"
                    style={{
                      color: "white",
                      fontSize: 15,
                      fontFamily: "Space Grotesk"
                    }}
                  >
                    Activités
                  </Button>
                </Popover>
                <Button
                  href="projects"
                  type="text"
                  style={{
                    color: "white",
                    fontSize: 15,
                    fontFamily: "Space Grotesk"
                  }}
                >
                  Réalisations
                </Button>
              </Space>
            </Col>
            <Col
              span={6}
              style={{
                textAlign: "right",
                display: "flex",
                direction: "rtl"
              }}
            >
              <Space>
                <Button
                  href="contact"
                  type="default"
                  style={{
                    backgroundColor: "#f6921c30",
                    color: "#f6921c",
                    border: "1px solid #f6921c",
                    borderRadius: 4,
                    fontFamily: "Space Grotesk"
                  }}
                  size="large"
                >
                  Contactez Nous
                </Button>
              </Space>
            </Col>
          </Row>
          {props.content}
        </div>
      </>
    );
  }
}
