import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "./pages/nodeFound";
import "antd/dist/antd.css";
import Contact from "./pages/contact";
import Index from "./pages";
import "./styles.css";
import Vrd from "./pages/vrd";
import MaconnerieGenerale from "./pages/maçonnerie-generale";
import SecondOeuvre from "./pages/secondoeuvre";
import Pool from "./pages/piscine";
import ContactUs from "./pages/contactus";
export default function App() {
  return (
    <>
      <Routes>
        <Route index element={<Index />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="*" element={<NotFound />} />
        <Route path="vrd" element={<Vrd />} />
        <Route path="maconnerie-generale" element={<MaconnerieGenerale />} />
        <Route path="second-oeuvre" element={<SecondOeuvre />} />
        <Route path="piscine" element={<Pool />} />
      </Routes>
    </>
  );
}
