import { Breadcrumb, Col, Divider, Row, Typography, Button, Card } from "antd";
import NavBar from "../components/navbar";

export default function SecondOeuvre() {
  return (
    <>
      <NavBar />
      <div
        style={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1592581912637-222dcc696ac4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80)",
          height: 200,
          width: "100%",
          backgroundPosition: "top",
          backgroundSize: "cover"
        }}
      >
        <div
          style={{
            backgroundImage: "linear-gradient( #000000, #00000095)",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0px 7%"
          }}
        >
          <Typography style={{ color: "white", fontSize: 30 }}>
            Second Oeuvre
          </Typography>
        </div>
      </div>
      <div style={{ padding: "40px 7%" }}>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Card style={{ padding: 0 }}>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="vrd"
              >
                V.R.D
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="maconnerie-generale"
              >
                Maçonnerie Generale
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="second-oeuvre"
              >
                Second Oeuvre
              </Button>
              <Button
                block
                style={{ textAlign: "left" }}
                type="text"
                href="piscine"
              >
                Piscine
              </Button>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            <div
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1600585154084-4e5fe7c39198?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)",
                width: "100%",
                height: "240px",
                borderRadius: 6,
                backgroundPosition: "center",
                backgroundSize: "cover"
              }}
            />
            <Typography style={{ fontSize: 20, marginTop: "40px" }}>
              Voici une liste non exhaustive des travaux que nous sommes en
              capacité de pouvoir réaliser grâce au savoir-faire de nos
              collaborateurs <br />
              - L’isolation thermique et phonique par mousse polyuréthane
              projetée La chaleur ne pourra pas s’échapper et le froid ne pourra
              pas entrer grâce aux système de microbulles d’air. Le pouvoir
              isolant de la mousse polyuréthane est ce qu’il se fait de mieux
              avec une valeur d’isolation entre 0,025 et 0,027 W/m2K. Ces
              chiffres sont supérieurs à ceux des autres isolants. En effet cela
              signifie que la chaleur les traverse plus rapidement qu’avec la
              mousse polyuréthane. La mousse permet aussi une très bonne
              isolation acoustique et une résistance thermique dans la pièce
              dans laquelle la projection de mousse polyuréthane a été faite.
              <br /> - Le revêtement intérieur. <br />
              - Les cloisons intérieures. <br />
              - Les menuiseries intérieures.
              <br />
              - Les escaliers. <br />
              - La plomberie et l’installation du
              chauffage/ventilation/climatisation <br />
              - L’électricité <br />
              - La peinture <br />
              - Le plafond tendu <br />- Aménagement de combles
            </Typography>
          </Col>
        </Row>
      </div>
    </>
  );
}
