import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Carousel, Col, Divider, Row, Space, Typography } from "antd";
import imageDisp from "../data/fbPhoto";

export default function Sider() {
  return (
    <div style={{ marginTop: 100 }}>
      <Row>
        <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Typography
            style={{
              color: "white",
              fontFamily: "Space Grotesk",
              fontSize: 18
            }}
          >
            BIENVENUE CHEZ
          </Typography>
          <Typography
            style={{
              color: "white",
              fontFamily: "Space Grotesk",
              fontSize: 50,
              fontWeight: 900
            }}
          >
            CONSTRUCTIONS SIMON
          </Typography>
          <Typography
            style={{
              color: "lightgray",
              fontFamily: "Space Grotesk",
              fontSize: 18,
              width: "70%"
            }}
          >
            Avec plus de 10 ans d'expérience, l'entreprise CONSTRUCTIONS SIMON
            est l'interlocuteur privilégié pour la réalisation de l'ensemble de
            vos projets en neuf ou rénovation sur tous lots d'ouvrage
            <Divider style={{ borderColor: "gray" }} />
          </Typography>
          <Space size={40} style={{ marginTop: 20, marginBottom: 20 }}>
            <Button
              size="large"
              type="default"
              style={{
                backgroundColor: "#f6921c30",
                color: "#f6921c",
                border: "1px solid #f6921c",
                borderRadius: 4,
                fontFamily: "Space Grotesk"
              }}
            >
              Contactez Nous <ArrowRightOutlined />
            </Button>
            <Button
              size="large"
              type="text"
              style={{
                color: "white",
                borderBottom: "1px solid white",
                borderRadius: 0,
                fontFamily: "Space Grotesk",
                padding: 0
              }}
            >
              En Savoir Plus
            </Button>
          </Space>
        </Col>
        <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Carousel
            autoplay
            style={{
              marginBottom: "20px",
              borderRadius: 10
            }}
          >
            {imageDisp}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
}
