import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Space,
  Button,
  message
} from "antd";
import NavBar from "../components/navbar";
import Map, { Marker } from "react-map-gl";
import emailjs from "@emailjs/browser";

function send(values) {
  var templateParam = {
    from_name: values.name,
    to_name: "Constructions SIMON",
    message: values.message + " " + values.phone + " " + values.mail
  };
  emailjs
    .send(
      "service_0f3ai1v",
      "template_0ij9k1m",
      templateParam,
      "p-Ciw2XCn-8ZtQFHE"
    )
    .then(() => {
      message.success("Message envoyé avec succés");
      window.location.reload();
    });
}

export default function ContactUs() {
  return (
    <>
      <NavBar />
      <div style={{ padding: "40px 7%" }}>
        <Row gutter={[40, 40]}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
            <div>
              <Typography style={{ fontSize: 24, fontWeight: 600 }}>
                Nous Trouver
              </Typography>
              <Typography>
                Constructions SIMON <br />
                34 Avenue Du Géneral Leclerc <br />
                89140 Pont Sur Yonne
              </Typography>
              <Map
                initialViewState={{
                  latitude: 48.28183677464244,
                  longitude: 3.2147687173057182,
                  zoom: 13
                }}
                style={{ width: "100%", height: 300, overflow: "hidden" }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                mapboxAccessToken="pk.eyJ1IjoidHNpbm92ZWMiLCJhIjoiY2ttYzljZDVwMjlkejJucDFzcGJ5bzNrZiJ9.s7DJkWBlwctYVQhKS0VdxQ"
              >
                <Marker
                  latitude={48.28183677464244}
                  longitude={3.2147687173057182}
                  color="orange"
                  anchor="bottom"
                  pitchAlignment="map"
                />
              </Map>
            </div>
          </Col>

          <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
            <Typography style={{ fontSize: 24, fontWeight: 600 }}>
              Nous Contacter
            </Typography>
            <Form layout="vertical" onFinish={send}>
              <Form.Item label="Nom complet" name="name" required>
                <Input />
              </Form.Item>
              <Row gutter={[40]}>
                <Col span={12}>
                  <Form.Item label="Mail" name="mail" required>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Téléphone" name="phone" required>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Objet" name="objet" required>
                <Input />
              </Form.Item>
              <Form.Item label="Message" name="message" required>
                <Input.TextArea />
              </Form.Item>
              <Button type="primary" block htmlType="submit">
                Envoyer
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}
