import { Divider, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

export default function NotFound() {
  return (
    <>
      <div style={{ padding: "30vh 0px" }}>
        <div
          style={{
            margin: "auto",
            width: "fit-content",
            height: "fir-content",
            padding: "30px",
            textAlign: "center",
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: 4
          }}
        >
          <Typography style={{ fontSize: "26px", color: "gray" }}>
            PAGE NON TROUVÉE <br /> 404
          </Typography>
          <Divider />
          <Typography.Link href="/">
            <ArrowLeftOutlined /> Retour à l'acceuil
          </Typography.Link>
        </div>
      </div>
    </>
  );
}
